import Box from "@mui/material/Box";
import * as React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Banner1 from "../../assets/images/banner1.png";
import PrevSelectedData from "../prevSelectedData";
import StepFour from "../stepFour";
import StepOne from "../stepOne";
import StepThree from "../stepThree";
import StepTwo, { ICardTypeDetails } from "../stepTwo";
import BannerSwiper from "../swiper";

import { Modal, Stack } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validateCarPlate } from "../../apis/order";
import { verifyOtp } from "../../apis/otp";
import { getParkingList } from "../../apis/parking";
import { createWaitingList } from "../../apis/waitingList";
import BookingConfirm from "../bookingConfirm";
import type { IBookingDetails, ICarParkInfo, IErrorMessages } from "./type";

const steps = [
  {
    step: 0,
    title: "請選擇租用停車場",
  },
  { step: 1, title: "" },
  { step: 2, title: "" },
  { step: 3, title: "" },
  { step: 4, title: "" },
  { step: 5, title: "" },
];

export const TOMMOROW = dayjs().add(1, "day").toDate();

const StepperContainer = () => {
  const navigate = useNavigate();

  // for entering by scanning qr code
  const [searchParams] = useSearchParams();
  const _carParkId = searchParams.get("carParkId");

  // state start

  // control step state
  const [activeStep, setActiveStep] = useState(0);

  // step one car parking info display
  const [carParkInfo, setCarParkInfo] = useState<Array<ICarParkInfo>>([]);

  // step one selected car park id
  const [selectedCarParkId, setSelectedCarParkId] = useState("");

  // step two selected order item
  const [selectedOrderItem, setSelectedOrderItem] = useState<ICardTypeDetails>({
    orderItemId: "",
    categoryId: "",
    itemTypeId: "",
    isFull: false,
    visible: true,
    deleted: false,
    parkingId: "",
    title: "",
    price: 0,
    quota: 0,
    priceId: "",
    interval: "",
    interval_count: 0,
  });

  // step three selected date
  const [selectedDate, setSelectedDate] = useState<Date>(TOMMOROW);

  // step four booking form
  const [bookingDetails, setBookingDetails] = useState<IBookingDetails>({
    name: "",
    carPlate: "",
    email: "",
    phone: "",
    validationCode: "",
  });

  // step four booking form error message
  const [errorMessages, setErrorMessages] = useState<IErrorMessages>({
    name: "",
    carPlate: "",
    email: "",
    phone: "",
    validationCode: "",
  });

  // step four confirm booking modal
  const [showStepFourConfirmBookingModal, setShowStepFourConfirmBookingModal] =
    useState(false);

  // state end

  // call create waiting list api
  const joinQueue = async () => {
    try {
      const createResult = await createWaitingList({
        parking_id: +selectedCarParkId,
        monthly_order_item_id: +selectedOrderItem.orderItemId,
        u_name: bookingDetails.name,
        u_email: bookingDetails.email,
        u_tel: bookingDetails.phone,
        u_lpn: bookingDetails.carPlate,
      });
      if (createResult.wl_token) {
        navigate(`/queue?wlt=${createResult.wl_token}`);
      }
    } catch (error) {}
  };

  // call validate car plate api
  const carPlateValidtor = async () => {
    try {
      const validateResult = await validateCarPlate(
        selectedCarParkId,
        bookingDetails.carPlate
      );
      if (validateResult) {
        if (validateResult.status === "repeat") {
          setShowStepFourConfirmBookingModal(true);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } catch (error) {}
  };

  // get parking list api
  const fetchAllParkingList = async () => {
    try {
      const response = await getParkingList();
      const data = response.map((item) => {
        return {
          carParkId: item.id.toString(),
          carParkTitle: item.display_name,
          parkingAddressText: item.location,
          carParkGoogleMapLink: item.map,
          carParkImage: item.cover,
          createdAt: item.createdAt,
          carParkUUId: item.car_park_id,
          carParkRegionName: item.name,
          visible: item.visible,
          deleted: item.deleted,
          galleryImages: item.gallery_images,
        };
      });
      setCarParkInfo(data);
    } catch (error) {}
  };

  // step four form submit handler
  const stepFourFormSubmitHandler = async (type: "queue" | "booking") => {
    try {
      const canByPass =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "LOCAL";
      // bypass otp for normal flow start
      if (type === "queue" && canByPass) {
        await joinQueue();
        return;
      }

      if (type === "booking" && canByPass) {
        carPlateValidtor();

        return;
      }
      // bypass flow end

      const verifyOtpResponse = await verifyOtp(
        bookingDetails.phone,
        bookingDetails.validationCode
      );

      if (verifyOtpResponse.message === "success") {
        if (type === "queue") {
          await joinQueue();
        } else {
          // call car plate validator before booking
          carPlateValidtor();
        }
      } else {
        setErrorMessages({
          ...errorMessages,
          validationCode: "驗證碼不正確",
        });
      }
    } catch (error) {
      setErrorMessages({
        ...errorMessages,
        validationCode: "驗證碼不正確",
      });
    }
  };

  const handleNext = (isFull?: boolean) => {
    // go to waiting list flow
    if (activeStep === 1 && isFull) {
      setActiveStep(2);
    } else if (activeStep === 3) {
      // form action button click
      if (selectedOrderItem.isFull) {
        stepFourFormSubmitHandler("queue");
        return;
      } else {
        stepFourFormSubmitHandler("booking");
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // back to step 2
    if (activeStep === 3 && selectedOrderItem.isFull) {
      setActiveStep(2);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const selectCarParkHandler = (id: string) => {
    setSelectedCarParkId(id);
    handleNext();
  };

  const getSelectedCarParkInfo = useMemo(
    () => carParkInfo.find((info) => info.carParkId === selectedCarParkId),
    [carParkInfo, selectedCarParkId]
  );

  const dateOnSelecteHandler = (date: Date) => {
    setSelectedDate(date);
  };

  // step four form input onchange handler
  const formInputOnchangeHandler = (
    key: keyof IBookingDetails,
    value: string
  ) => {
    setBookingDetails({
      ...bookingDetails,
      [key]: value,
    });
  };

  // step four form validator
  const formValidator = (key: keyof IErrorMessages, value: string) => {
    switch (key) {
      case "name":
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, name: "請輸入車主名稱" });
        } else {
          setErrorMessages({ ...errorMessages, name: "" });
        }
        break;
      case "email":
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, email: "請輸入電郵" });
        } else if (!emailRegex.test(value)) {
          setErrorMessages({ ...errorMessages, email: "不正確的電郵格式" });
        } else {
          setErrorMessages({ ...errorMessages, email: "" });
        }
        break;
      case "carPlate":
        if (value.trim().length === 0) {
          setErrorMessages({
            ...errorMessages,
            carPlate: "請輸入車牌號碼",
          });
        } else {
          setErrorMessages({ ...errorMessages, carPlate: "" });
        }
        break;
      case "phone":
        if (value.trim().length === 0) {
          setErrorMessages({ ...errorMessages, phone: "請輸入電話號碼" });
        } else {
          setErrorMessages({ ...errorMessages, phone: "" });
        }
        break;
      case "validationCode":
        if (value.trim().length === 0) {
          setErrorMessages({
            ...errorMessages,
            validationCode: "請輸入認證碼",
          });
        } else {
          setErrorMessages({ ...errorMessages, validationCode: "" });
        }
        break;

      default:
        break;
    }
  };

  // control next button disable or not
  const stepButtonStateController = (): boolean => {
    switch (activeStep) {
      case 0:
        return selectedCarParkId === "";
      case 1:
        return selectedOrderItem.orderItemId === "";
      case 2:
        return false;
      case 3:
        const hasInvalidFeild =
          errorMessages.name.length > 0 ||
          errorMessages.carPlate.length > 0 ||
          errorMessages.email.length > 0 ||
          errorMessages.phone.length > 0 ||
          errorMessages.validationCode.length > 0;
        const hasEmptyField =
          bookingDetails.name === "" ||
          bookingDetails.carPlate === "" ||
          bookingDetails.email === "" ||
          bookingDetails.phone === "" ||
          bookingDetails.validationCode === "";
        if (
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "LOCAL"
        ) {
          // bypass otp verification
          return false;
        }
        return hasEmptyField || hasInvalidFeild;
      default:
        return false;
    }
  };

  // get slider object content
  const getSlideObject = useCallback(() => {
    let INIT_OBJECT = [
      { image: Banner1, link: "https://goswap.com.hk/rent-with-park" },
      // { image: Banner2, link: "https://mrwrapper.com.hk/" },
    ];
    return INIT_OBJECT;
  }, [selectedCarParkId]);

  // close step four confirm booking modal
  const handleStepFourConfirmBookingModalClose = useCallback(() => {
    setShowStepFourConfirmBookingModal(false);
  }, []);

  // set selected parking id when entering by scanning qr code
  useEffect(() => {
    if (_carParkId === null) return;

    setSelectedCarParkId(_carParkId);
  }, [_carParkId]);

  // fetch parking list on component mount for step one
  useEffect(() => {
    fetchAllParkingList();
  }, []);

  // reset selected car type when car park changed
  useEffect(() => {
    setSelectedOrderItem({
      orderItemId: "",
      categoryId: "",
      itemTypeId: "",
      isFull: selectedOrderItem.isFull,
      visible: selectedOrderItem.visible,
      deleted: selectedOrderItem.deleted,
      parkingId: "",
      title: "",
      price: 0,
      quota: 0,
      priceId: "",
      interval: "",
      interval_count: 0,
    });
  }, [selectedCarParkId]);

  // reset selecte date when selected order item
  useEffect(() => {
    // const customDate = selectedOrderItem.default_start_date
    //   ? dayjs(selectedOrderItem.default_start_date).toDate()
    //   : TOMMOROW;

    setSelectedDate(selectedOrderItem.default_start_date || TOMMOROW);
  }, [selectedOrderItem]);

  return (
    <MainContainer>
      <StepWrapper>
        <React.Fragment>
          <Typography variant="h5" sx={{ my: 1 }}>
            {steps[activeStep].title}
          </Typography>

          <Box sx={{ width: "100%" }}>
            {/* car park select */}
            {activeStep === 0 && (
              <StepOne
                carParkInfo={carParkInfo}
                onCarParkSelect={selectCarParkHandler}
                selectedCarParkId={selectedCarParkId}
              />
            )}

            {/* subscription type select */}
            {activeStep === 1 && (
              <StepTwo
                carParkId={selectedCarParkId}
                orderItemId={selectedOrderItem.orderItemId}
                carParkTitle={
                  carParkInfo.find(
                    (carPark) => carPark.carParkId === selectedCarParkId
                  )?.carParkTitle || ""
                }
                onOtherItemSelect={(detail: ICardTypeDetails) => {
                  setSelectedOrderItem(detail);
                  handleNext(detail.isFull);
                }}
                galleryList={getSelectedCarParkInfo?.galleryImages}
              />
            )}

            {/* select date */}
            {activeStep === 2 && (
              <>
                {getSelectedCarParkInfo && (
                  <PrevSelectedData
                    carParkInfo={getSelectedCarParkInfo}
                    selectedOrderItem={selectedOrderItem}
                    selectedDate={selectedDate}
                  />
                )}
                <StepThree
                  selectedDate={selectedDate}
                  dateOnChange={(value: Date) => dateOnSelecteHandler(value)}
                  defaultStartDate={selectedOrderItem.default_start_date}
                  periodDays={selectedOrderItem.period_days}
                />
              </>
            )}

            {/* reigster for waiting list */}
            {activeStep === 3 && (
              <>
                {getSelectedCarParkInfo && (
                  <PrevSelectedData
                    carParkInfo={getSelectedCarParkInfo}
                    selectedOrderItem={selectedOrderItem}
                    selectedDate={selectedDate}
                  />
                )}
                <StepFour
                  formData={bookingDetails}
                  errorMessages={errorMessages}
                  formValidator={(key: keyof IBookingDetails, value: string) =>
                    formValidator(key, value)
                  }
                  formOnchange={(key: keyof IBookingDetails, value: string) =>
                    formInputOnchangeHandler(key, value)
                  }
                />
              </>
            )}

            {/* booking confirm */}
            {activeStep === 4 && (
              <BookingConfirm
                detail={{
                  ...bookingDetails,
                  otherItem: selectedOrderItem.title,
                  parkingAddress:
                    getSelectedCarParkInfo?.parkingAddressText || "",
                  parkingName: getSelectedCarParkInfo?.carParkTitle || "",
                  startDate: dayjs(selectedDate).format("YYYY-MM-DD"),
                  amount: String(selectedOrderItem.price),
                }}
                selectedOrderItem={selectedOrderItem}
                subscriptionItem={selectedOrderItem.title}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              my: "1rem",
            }}
          >
            {activeStep !== 0 && (
              <Button
                size="medium"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{
                  mr: 1,
                  ":disabled": {
                    color: "grey",
                  },
                  color: "white",
                }}
              >
                上一步
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep !== steps.length - 2 && (
              <Button
                size="medium"
                disabled={stepButtonStateController()}
                sx={{
                  backgroundColor: "waspYellow",
                  color: "white",
                  ":hover": {
                    backgroundColor: "white",
                    color: "waspYellow",
                  },
                  ":disabled": {
                    backgroundColor: "grey",
                    color: "waspLightYellow",
                  },
                }}
                onClick={() => handleNext()}
              >
                {activeStep === steps.length - 1
                  ? "完成"
                  : activeStep === 3 && selectedOrderItem.isFull
                    ? "預約"
                    : "下一步"}
              </Button>
            )}
          </Box>
        </React.Fragment>
      </StepWrapper>
      <div>
        <BannerSwiper slideObject={getSlideObject()} />
        <Footer>Powered by AI Parkingbees</Footer>
      </div>

      <Modal
        open={showStepFourConfirmBookingModal}
        onClose={handleStepFourConfirmBookingModalClose}
      >
        <Box sx={modalStyle}>
          <Typography
            color="waspDark"
            textAlign="center"
            variant="h6"
            component="h2"
            mb={4}
          >
            {`您的車牌 ${bookingDetails.carPlate} 已在${getSelectedCarParkInfo?.carParkTitle}租用車位中，確認繼續？`}
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              onClick={handleStepFourConfirmBookingModalClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "waspDark",
                color: "white",
                ":hover": {
                  backgroundColor: "waspDark",
                  color: "white",
                },
              }}
            >
              返回
            </Button>
            <Button
              onClick={() => {
                handleStepFourConfirmBookingModalClose();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }}
              variant="contained"
              fullWidth
              sx={{
                color: "white",
                backgroundColor: "waspYellow",
                ":hover": {
                  color: "white",
                  backgroundColor: "waspYellow",
                },
              }}
            >
              確認
            </Button>
          </Stack>
        </Box>
      </Modal>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StepWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Footer = styled.div`
  font-size: 12px;
  color: white;
  text-align: center;
  margin-top: 1rem;
`;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  bgcolor: "white",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

export default StepperContainer;
