import { Button, Checkbox, Link, Stack, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  createSubscriptionAndOrder,
  initStripeIntents,
} from "../../apis/subscription";

import { getAmountUnitText } from "../../helper";
import OrderDetailCard from "../orderDetailCard";
import { ICardTypeDetails } from "../stepTwo";
import { IBookingDetails } from "../stepperContainer/type";

export interface IConfirmDetails
  extends Omit<IBookingDetails, "validationCode"> {
  otherItem: string;
  parkingAddress: string;
  parkingName: string;
  startDate: string;
  endDate?: string;
  amount: string;
}

interface IBookingConfirmProps {
  detail: IConfirmDetails;
  selectedOrderItem: Pick<
    ICardTypeDetails,
    "orderItemId" | "priceId" | "interval" | "interval_count"
  >;
  token?: string;
  subscriptionItem?: string;
}

const BookingConfirm: FC<IBookingConfirmProps> = (props) => {
  const { detail, selectedOrderItem, token, subscriptionItem } = props;
  const [tncChecked, setTncChecked] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isBinding, setIsBinding] = useState(false);

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const unitText = getAmountUnitText(
    selectedOrderItem.interval,
    selectedOrderItem.interval_count
  );

  // try init stripe
  const fetchClientSecret = useCallback(async () => {
    try {
      const res = await initStripeIntents(
        detail.email,
        detail.name,
        detail.phone
      );
      if (res) {
        const responseBody = await res;
        console.log(`init intents responseBody success`);
        // console.log(responseBody);
        setClientSecret(responseBody.client_secret);
        setCustomerId(responseBody.customer);
      }
    } catch (error) {
      //@ts-ignore
      setErrorMessage(error.message);
    }
  }, []);

  const autoCreateSubscription = async (
    payment_methodId: string,
    paymentIntentId: string
  ) => {
    try {
      const createResult = await createSubscriptionAndOrder({
        priceId: selectedOrderItem.priceId,
        name: detail.name,
        email: detail.email,
        phone: detail.phone,
        startDate: detail.startDate,
        carPlate: detail.carPlate,
        monthlyOrderItemId: +selectedOrderItem.orderItemId,
        paymentMethodId: payment_methodId,
        paymentIntentId: paymentIntentId,
        customerId: customerId,
        type: "wasp",
        wl_token: token || undefined,
      });

      // error case
      if (!createResult) {
        setErrorMessage(`發生未知錯誤`);
        setIsBinding(false);
      } else {
        // normal case
        if (createResult.id) {
          navigate(`/payment-result`, {
            state: {
              orderId: createResult.id,
            },
          });
        } else {
          // special case

          if (createResult === "duplicated") {
            setErrorMessage(`此停車場已存在相同車牌的訂閱！`);
          } else if (createResult === "deleted") {
            setErrorMessage(`找不到該訂閱項目`);
          } else if (createResult === "fulled") {
            setErrorMessage(`抱歉，當前車場已滿。`);
          }
          setIsBinding(false);
        }
      }
    } catch (error) {
      setIsBinding(false);
    } finally {
      setIsBinding(false);
    }
  };

  const checkOutHandler = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    try {
      setIsBinding(true);
      if (clientSecret) {
        const { setupIntent, error } = await stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: card ? { card } : undefined,
          }
        );

        if (error) {
          setIsBinding(false);
          //@ts-ignore
          setErrorMessage(error.message);
        } else {
          // Setup Intent was successful
          console.log("setupIntent success");
          if (setupIntent.status === "succeeded") {
            autoCreateSubscription(
              setupIntent?.payment_method as string,
              setupIntent.id
            );
          }
        }
      } else {
        setErrorMessage("unexpected error");
      }
    } catch (error) {
      setIsBinding(false);
      // @ts-ignore
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  const endDate = dayjs(detail?.startDate).add(1, "month").format("YYYY-MM-DD");

  return (
    <Stack gap={2}>
      {/* <Typography variant="h5" color="white" fontWeight={900}>
        訂閱資料
      </Typography> */}

      <OrderDetailCard
        name={detail.name}
        email={detail.email}
        phone={detail.phone}
        carPlate={detail.carPlate}
        parkingName={detail.parkingName}
        parkingAddress={detail.parkingAddress}
        startDate={detail.startDate}
        endDate={endDate}
        amount={+detail.amount}
        amountUnit={unitText}
        interval_count={selectedOrderItem.interval_count}
        subscriptionItem={subscriptionItem}
      />
      <Stack>
        <Typography variant="caption" color="white" fontWeight="bold" px={1.5}>
          *月租車位計劃將自動續期。計劃可隨時取消。
          <Link
            href={"/example.pdf"}
            underline="hover"
            color="waspYellow"
            target="_blank"
            borderColor="waspYellow"
            ml="2px"
          >
            進一步了解
          </Link>
        </Typography>

        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={tncChecked}
            onChange={() => setTncChecked((prev) => !prev)}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "waspYellow",
              },
            }}
          />
          <Typography color="white" variant="button">
            我同意
            <Link
              href="https://parkingbees.com.hk/assets/pdf/parkingbees-tnc.pdf"
              underline="hover"
              color="waspYellow"
              target="_blank"
              borderColor="waspYellow"
              ml="2px"
            >
              條款及細則
            </Link>
            及
            <Link
              href="https://parkingbees.com.hk/assets/pdf/parkingbees-monthly-tnc.pdf"
              underline="hover"
              color="waspYellow"
              target="_blank"
              borderColor="waspYellow"
              ml="2px"
            >
              月租車位使用條款
            </Link>
          </Typography>
        </Stack>
        {clientSecret && (
          <Stack
            justifyContent="center"
            borderRadius={2}
            mb={1}
            p={2}
            bgcolor="white"
          >
            <CardElement
              options={{
                style: {
                  base: {
                    backgroundColor: "white",
                    fontSize: "16px",
                    color: "#fac80b",
                    "::placeholder": {
                      color: "#232636",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                hidePostalCode: true,
              }}
            />
          </Stack>
        )}
        {errorMessage && (
          <Typography color="error" mb={2} px={1}>
            {errorMessage}
          </Typography>
        )}
        <Button
          onClick={checkOutHandler}
          variant="contained"
          fullWidth
          type="submit"
          disabled={!clientSecret || !tncChecked || isBinding}
          sx={{
            background: "waspYellow",
            color: "black",
            ":hover": {
              backgroundColor: "waspYellow",
              color: "black",
            },
            ":disabled": {
              backgroundColor: "grey",
              color: "white",
            },
          }}
        >
          {isBinding ? "訂閱中..." : `訂閱`}
        </Button>
      </Stack>
    </Stack>
  );
};

export default BookingConfirm;
