import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import { HTMLAttributes, useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as BikeBlue } from "../../assets/images/bike_b.svg";
import { ReactComponent as BikeYellow } from "../../assets/images/bike_y.svg";
import location from "../../assets/images/location.png";
import { ReactComponent as CarBlue } from "../../assets/images/pcar_b.svg";
import { ReactComponent as CarYellow } from "../../assets/images/pcar_y.svg";
import { intervalMapping } from "../../constants";
import GalleryPreviewModal from "../galleryPreviewModal";
import { ICardTypeDetails } from "../stepTwo";
import { ICarParkInfo } from "../stepperContainer/type";

const AWS_GLOBAL_IMAGE_URL = process.env.REACT_APP_AWS_GLOBAL_IMAGES_BUCKET_URL;

const PrevSelectedData = ({
  carParkInfo,
  selectedOrderItem,
  selectedDate,
}: {
  carParkInfo: ICarParkInfo;
  selectedOrderItem: ICardTypeDetails;
  selectedDate?: Date;
}) => {
  const {
    carParkTitle,
    parkingAddressText,
    carParkGoogleMapLink,
    carParkId,
    carParkImage,
    galleryImages,
  } = carParkInfo;

  const [showGallery, setShowGallery] = useState(false);

  const swiperData = galleryImages
    ?.split(",")
    .map((url) => `${AWS_GLOBAL_IMAGE_URL}/${url}`);

  const unitText = () => {
    if (selectedOrderItem.interval_count > 1) {
      return `/${selectedOrderItem.interval_count}個${intervalMapping[selectedOrderItem.interval as keyof typeof intervalMapping]}`;
    } else {
      return `/${intervalMapping[selectedOrderItem.interval as keyof typeof intervalMapping]}`;
    }
  };

  const handleModalOpen = useCallback(() => {
    setShowGallery(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowGallery(false);
  }, []);

  return (
    <Stack gap={1}>
      <Typography
        variant="h5"
        color="white"
        sx={{ fontWeight: "bold", mb: "0.3rem" }}
      >
        {carParkTitle} {selectedOrderItem.isFull && <span></span>}
      </Typography>

      <Stack direction="row" justifyContent="space-between">
        <Link
          variant="h6"
          color="waspYellow"
          href={carParkGoogleMapLink}
          target="_blank"
          alignItems="center"
        >
          <img src={location} width={20} height={20} />
          {parkingAddressText}
        </Link>
        {swiperData && (
          <IconButton onClick={handleModalOpen}>
            <CollectionsOutlinedIcon style={{ fill: "white" }} />
          </IconButton>
        )}
      </Stack>

      <CarTypeWrapper fulled={selectedOrderItem.isFull ? 1 : 0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
        >
          <Stack direction="column" gap={1} justifyContent="space-between">
            <Stack direction="row" gap={1} alignItems="center">
              {(selectedOrderItem.isFull && (
                <>
                  {selectedOrderItem.itemTypeId === "2" ? (
                    <BikeYellow width={40} height={40} />
                  ) : (
                    <CarYellow width={40} height={40} />
                  )}
                </>
              )) || (
                <>
                  {selectedOrderItem.itemTypeId === "2" ? (
                    <BikeBlue width={40} height={40} />
                  ) : (
                    <CarBlue width={40} height={40} />
                  )}
                </>
              )}

              <Typography sx={{ fontWeight: "900" }} variant="caption">
                {selectedOrderItem.title}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Typography sx={{ fontWeight: "900" }} variant="h5">
                {selectedOrderItem.interval_count > 1
                  ? (
                      selectedOrderItem.price / selectedOrderItem.interval_count
                    ).toLocaleString("zh-HK", {
                      style: "currency",
                      currency: "HKD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : selectedOrderItem.price.toLocaleString("zh-HK", {
                      style: "currency",
                      currency: "HKD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
              </Typography>
              <Typography
                sx={{ fontWeight: "900" }}
                variant="body1"
              >{`${unitText()} ${
                selectedOrderItem.isFull ? "(已滿)" : ""
              }`}</Typography>
            </Stack>
          </Stack>
          {selectedOrderItem.isFull && <Stack>登記輪候</Stack>}
        </Stack>
      </CarTypeWrapper>
      <GalleryPreviewModal
        open={showGallery}
        onClose={handleModalClose}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: "rgba(0, 0, 0, 0.5)" }, // Adjust the opacity here
          },
        }}
        swiperData={swiperData}
      />
    </Stack>
  );
};

const CarTypeWrapper = styled.div<HTMLAttributes<"div"> & { fulled: 1 | 0 }>`
  display: flex;
  font-weight: bold;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: ${(props) => (props.fulled ? "#842822" : "#fac80b")};
  color: ${(props) => (props.fulled ? "white" : "black")};
`;

export default PrevSelectedData;
