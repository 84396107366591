import React from "react";

import { Box, Stack, TextField } from "@mui/material";
import { IBookingDetails, IErrorMessages } from "../stepperContainer/type";
import ValidationCodeButton from "../validationButton";

interface IStepFourProps {
  formOnchange: (key: keyof IBookingDetails, value: string) => void;
  formValidator: (key: keyof IBookingDetails, value: string) => void;
  formData: IBookingDetails;
  errorMessages: IErrorMessages;
}

const StepFour = (props: IStepFourProps) => {
  const { formOnchange, formValidator, formData, errorMessages } = props;

  return (
    <Stack my="1rem" borderRadius="8px">
      <Box
        bgcolor="white"
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          borderRadius: 2,
          gap: 1,
        }}
        autoComplete="off"
      >
        <TextField
          required
          id="username"
          label="車主名稱"
          fullWidth
          variant="filled"
          value={formData.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            formValidator("name", event.target.value);
            formOnchange("name", event.target.value);
          }}
          error={errorMessages.name.length > 0}
          helperText={errorMessages.name}
        />
        <TextField
          required
          id="carPlate"
          label="車牌"
          fullWidth
          inputProps={{ maxLength: 8 }}
          variant="filled"
          value={formData.carPlate}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const regex = /^[a-zA-Z0-9]{0,8}$/;
            if (regex.test(event.target.value)) {
              const upperCarPlate = event.target.value.toUpperCase();
              const formattedCarPlate = upperCarPlate
                .replaceAll("I", "")
                .replaceAll("O", "")
                .replaceAll("Q", "");

              formValidator("carPlate", formattedCarPlate);
              formOnchange("carPlate", formattedCarPlate);
            }
          }}
          error={errorMessages.carPlate.length > 0}
          helperText={errorMessages.carPlate}
        />
        <TextField
          required
          id="email"
          label="電郵"
          fullWidth
          variant="filled"
          value={formData.email}
          type="email"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            formValidator("email", event.target.value);
            formOnchange("email", event.target.value);
          }}
          error={errorMessages.email.length > 0}
          helperText={errorMessages.email}
        />
        <TextField
          required
          id="phone"
          label="電話"
          fullWidth
          variant="filled"
          value={formData.phone}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const digitOnlyRegex = /^[0-9]{0,8}$/;
            if (digitOnlyRegex.test(event.target.value)) {
              formValidator("phone", event.target.value);
              formOnchange("phone", event.target.value);
            }
          }}
          InputProps={{
            endAdornment: <ValidationCodeButton phone={formData.phone} />,
          }}
          error={errorMessages.phone.length > 0}
          helperText={errorMessages.phone}
        />
        <TextField
          required
          id="validationCode"
          label="驗證碼"
          inputProps={{ maxLength: 6 }}
          fullWidth
          variant="filled"
          value={formData.validationCode}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            formValidator("validationCode", event.target.value);
            formOnchange("validationCode", event.target.value);
          }}
          error={errorMessages.validationCode.length > 0}
          helperText={errorMessages.validationCode}
        />
      </Box>
    </Stack>
  );
};

export default StepFour;
